<template>
    <div>
        <a-drawer title="工单转派" :width="720" :visible="isVisibleDrawe" :body-style="{ paddingBottom: '80px' }" @close="onClose">
            <div class="drawer-content">
                <a-form-model title="1d2" layout="vertical" ref="ruleForm" :wrapper-col="{ span: 18 }">
                    <div>负责人</div>
                    <a-divider></a-divider>
                    <a-row>
                        <a-col :span="12">
                            <a-form-model-item label="主办部门">
                                <a-cascader :options="applicantIds" @change="getDepartment" placeholder="请选择部门"></a-cascader>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="负责人">
                                <a-select placeholder="请选择负责人" v-model="form.principalDTOList[0].manageUserId">
                                    <a-select-option v-for="(item,index) in applicantPeoples" :value="item.id" :key="index">
                                        {{item.actualName}}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <div>协同人<a-button style="float: right" type="danger" ghost @click="delAll">全部删除</a-button></div>
                    <a-divider></a-divider>
                    <a-row v-for="(item, index) in form.collaboratorDTOList" :key="index">
                        <a-col :span="12">
                            <a-form-model-item label="协同部门" >
                                <a-cascader v-model="coApplicants[index]" :options="applicantIds" @change="getCoDepartment(index)" placeholder="请选择部门"></a-cascader>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="协同人">
                                <a-select placeholder="请选择协同人" v-model="form.collaboratorDTOList[index].manageUserId">
                                    <a-select-option v-for="(it,i) in caDTO[index]" :value="it.id" :key="i">
                                        {{it.actualName}}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-button type="primary" ghost @click="coAdd">添加</a-button>
                </a-form-model>
            </div>
            <div class="drawer-footer">
                <a-button :style="{ marginRight: '8px' }" @click="onClose">
                    关闭
                </a-button>
                <a-button type="primary" @click="onSubmit"> 提交 </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import {departmentList} from "@/api/basic/department"
import {findToUser} from "@/api/basic/department"
import {updateDesignate, orderSchedule} from "@/api/basic/Workorder"
export default {
    name: "upDateTransDrawer",
    props: {
		visible: Boolean,
        updateTransId: Number,
        orderCode: String
	},
    watch: {
		visible: {
			handler(newValue) {
				this.isVisibleDrawe = newValue;
			},
			immediate: true,
		},
	},
    data() {
        return {
            form: {
                manageWorkOrderId: undefined,
                principalDTOList: [
                    {
                        departmentId: undefined,
                        positionId: undefined,
                        userType: 1,
                        manageUserId: undefined
                    }
                ],
                collaboratorDTOList: []
            },
            isVisibleDrawe: false,
            applicantIds: [],
            applicantPeoples: [],
            //协同人DTO
            coApplicants: [],
            caDTO: [],
            id: 1,
        }
    },
    updated() {
        this.getDetail()
    },
    created() {
        this.getApi();
    },
    methods: {
        onClose() {
			this.$emit("onClose");
		},
        getApi() {
            departmentList().then(res => {
                let data = res.data;
                let applicantIds = [];
                data.forEach(ele => {
                    let childList = []
                    ele.childList.forEach(el => {
                        let chList = [];
                        el.childList.forEach(e => {
                            let obj = {
                                value: e.id,
                                label: e.name
                            };
                            chList.push(obj)
                        })
                        let obj = {
                            value: el.id,
                            label: el.name,
                            children: chList,
                        };
                        childList.push(obj)
                    })
                    let obj = {
                        value: ele.id,
                        label: ele.name,
                        children: childList
                    };
                    applicantIds.push(obj)
                });
                this.applicantIds = applicantIds;
            });
        },
        //获取工单进度
        getDetail() {
            orderSchedule({manageWorkOrderId: this.updateTransId}).then(res => {
                let data = res.data
            })
        },
        //选择物业后的回调
        getDepartment(val) {
            this.form.principalDTOList[0].departmentId = val[1];
            this.form.principalDTOList[0].positionId = val[2];
            findToUser({pageNum: 1,size: 10000,positionId: val[2]}).then(res => {
                let data = res.data.rows;
                this.applicantPeoples = data
            })
        },
        onSubmit() {
            this.form.manageWorkOrderId = this.updateTransId;
            updateDesignate(this.form).then(res => {
                if(res.code === 200){
					this.$message.success(res.msg)
					this.success()
					this.addClose()
				} else {
					this.$message.error(res.msg)
				}
            })
        },
        success(){
			this.$emit('success')
		},
        addClose(){
			this.isVisibleDrawe = false;
            this.form.principalDTOList= [
                    {
                        departmentId: undefined,
                        positionId: undefined,
                        userType: 1,
                        manageUserId: undefined
                    }
                ];
			this.form.collaboratorDTOList =  [];
		},
        //协同人
        coAdd() {
            let obj = {
                departmentId: undefined,
                positionId: undefined,
                userType: 2,
                manageUserId: undefined,
            }
            this.form.collaboratorDTOList.push(obj);
        },
        getCoDepartment(index) {
            this.form.collaboratorDTOList[index].departmentId = this.coApplicants[index][1];
            this.form.collaboratorDTOList[index].positionId = this.coApplicants[index][2];
            findToUser({pageNum: 1,size: 10000,positionId: this.coApplicants[index][2]}).then(res => {
                let data = res.data.rows;
                this.caDTO.push(data)
            })
        },
        delAll() {
            this.form.collaboratorDTOList = []
        }
    }
}
</script>

<style>

</style>