<template>
  <div>
    <a-drawer
      title="添加工单"
      :width="720"
      :visible="isVisibleDrawe"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <div class="drawer-content">
        <a-form-model
          title="1d2"
          layout="vertical"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :wrapper-col="{ span: 18 }"
        >
          <div>基本信息</div>
          <a-divider></a-divider>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="申请人身份" prop="identity">
                <a-select v-model="form.identity" placeholder="请选择申请人身份" @change="form.applicantId=undefined">
                  <a-select-option v-for="(item, index) in options.identity" :key="index" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="form.identity == 1">
              <a-form-model-item label="选择房屋" prop="estateId" :rules="[{ required: true, message: '请选择房屋' }]">
                <!-- <a-select v-model="form.estateId" placeholder="请选择房屋" @change="getAppName">
                  <a-select-option v-for="(item, index) in options.estateId" :key="index" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select> -->
                <a-cascader v-model="casVal" @change="chooseHouse" :field-names="{label: 'name', value: 'id', children: 'childList' }" :options="options.estateId" placeholder="请选择房屋">
                </a-cascader>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-else>
              <a-form-model-item label="选择岗位" prop="positionId" :rules="[{ required: true, message: '请选择岗位' }]">
                <a-cascader v-model="form.positionId" :options="options.applicantId" @change="getDepartmentName" placeholder="请选择岗位"></a-cascader>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="申请人姓名" prop="applicantId">
                <a-select v-if="form.identity == 1" v-model="form.applicantId" placeholder="请选择申请人">
                  <a-select-option v-for="(item,index) in applicantIds" :value="item.id" :key="index">
                    {{item.name}}
                  </a-select-option>
                </a-select>
                <a-select v-else v-model="form.applicantId" placeholder="请选择申请人">
                  <a-select-option v-for="(item,index) in departmentIds" :value="item.id" :key="index">
                    {{item.actualName}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="工单类型" prop="workOrderTypeId">
                <a-select placeholder="请选择工单类型" v-model="form.workOrderTypeId">
                  <a-select-option v-for="(item,index) in options.workOrderTypeId" :value="item.id" :key="index">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="预约地址" prop="reserveAddress">
                <a-input placeholder="请输入预约地址" v-model="form.reserveAddress"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="预约到场时间" prop="reserveDate">
                <a-date-picker style="width: 253px" v-model="form.reserveDate" value-format="YYYY-MM-DD HH:mm:ss"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              工单照片
              <!-- head -->
              <a-upload
                list-type="picture-card"
                :action="`${$upload}`"
                :headers="uploadHeaders"
                accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
                @preview="handlePreview"
                @change="picChange"
                :file-list="headList"
                :beforeUpload="beforeUpload"
              >
                <div v-if="headList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    上传照片
                  </div>
                </div>
              </a-upload>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="工单内容" prop="content">
                <a-textarea style="width: 824px" placeholder="请输入工单内容" v-model="form.content"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          关闭
        </a-button>
        <a-button type="primary" @click="onSubmit"> 提交 </a-button>
      </div>
    </a-drawer>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { form, rules, options, uploadHeaders } from "./form.js"
import {findTenantByEstateId} from "@/api/basic/resident"
import {findToUser} from "@/api/basic/department"
import {addWorkOrder} from "@/api/basic/Workorder"
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}
export default {
	name: "addWork",
	data() {
		return {
			isVisibleDrawe: false,
      uploadHeaders,
      previewVisible:false,
      previewImage:'',
      headList: [],
      form,
      rules,
      options,
      applicantIds: [],
      departmentIds: [],
      casVal:[]
		};
	},
	props: {
		visible: Boolean,
	},
	methods: {
		onClose() {
			this.$emit("onClose");
		},
    addClose(){
			this.$emit('close');
			this.headList = [];
			this.$refs.ruleForm.resetFields();
      this.form.estateId = undefined;
      this.form.positionId = undefined;
		},
		success(){
			this.$emit('success')
		},
    onSubmit() {
      // 照片
			let pic = []
			for(let item of this.headList){
				// 新增的照片
				if(item.response){
					pic.push(item.response.data)
				}else{
				// 已添加的照片
					pic.push(item.url.split('/')[item.url.split('/').length-1])
				}
			};
      this.form.imgUrls = pic;
      let obj = {
        ...this.form
      }
      if(obj.identity == 2) {
        obj.positionId = obj.positionId[2]
      }
      this.$refs.ruleForm.validate(async valid => {
        if(valid) {
          addWorkOrder(obj).then(res => {
            if(res.code === 200){
							this.$message.success(res.msg)
							this.success()
							this.addClose()
						} else {
							this.$message.error(res.msg)
						}
          })
        }
      });
    },
    //上传限制
    beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		}, //图片上传
    async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
    picChange(info){
			this.headList = info.fileList
		},
    handleCancel() {
			this.previewVisible = false;
		},
    //选择房屋后的回调
    getAppName(val) {
        this.form.applicantId = undefined;
        findTenantByEstateId({estateId: val}).then(res => {
           let data = res.data;
           this.applicantIds = data;
        })
    },
    //选择物业后的回调
    getDepartmentName(val) {
        findToUser({pageNum: 1,size: 10000,positionId: val[2]}).then(res => {
          let data = res.data.rows;
          this.departmentIds = data
        })
    },
    //选择房屋
    chooseHouse(val) {
      let v = val;
      let index = val.length
      this.form.estateId = v[index-1]
      this.form.applicantId = undefined;
      findTenantByEstateId({estateId: v[index-1]}).then(res => {
         let data = res.data;
         this.applicantIds = data;
      })
    }
	},
	watch: {
		visible: {
			handler(newValue) {
				this.isVisibleDrawe = newValue;
			},
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
</style>
