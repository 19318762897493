import httpService from "@/request"

/**
 *
 * 住户
 * @param {*} params
 * @returns
 * 
 * 住户列表
 */
export function residentList(params) {
	return httpService({
		url: `/user/resident/list`,
		method: 'get',
		params: params,
	})
}
// 新增住户
export function residentInsert(params) {
	return httpService({
		url: `/user/resident/insert`,
		method: 'post',
		data: params,
	})
}
// 修改住户
export function residentUpdate(params) {
	return httpService({
		url: `/user/resident/update`,
		method: 'post',
		data: params,
	})
}
export function residentInfo(params) {
	return httpService({
		url: `/user/resident/findById`,
		method: 'get',
		params: params,
	})
}
// 迁出
export function moveOut(params) {
	return httpService({
		url: `/user/resident/moveOut`,
		method: 'post',
		data: params,
	})
}
// 迁入
export function moveIn(params) {
	return httpService({
		url: `/user/resident/moveIn`,
		method: 'post',
		data: params,
	})
}
// 根据房屋查租户
export function findTenantByEstateId(params) {
	return httpService({
		url: `/user/resident/findTenantByEstateId`,
		method: 'get',
		params: params,
	})
}
// 根据房屋查业主
export function findOwnerByEstateId(params) {
	return httpService({
		url: `/user/resident/findOwnerByEstateId`,
		method: 'get',
		params: params,
	})
}
// 住户详情
export function residentDetail(params) {
	return httpService({
		url: `/user/resident/findDetail`,
		method: 'get',
		params: params,
	})
}
// 住户审核
// 审核列表
export function reviewList(params) {
	return httpService({
		url: `/user/review/list`,
		method: 'get',
		params: params,
	})
}
// 审核通过 不通过
export function review(params) {
	return httpService({
		url: `/user/review/review`,
		method: 'post',
		data: params,
	})
}
