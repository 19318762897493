<template>
  <div>
      <a-drawer title="完成工单" :width="720" :visible="isVisibleDrawe" :body-style="{ paddingBottom: '80px' }" @close="onClose">
          <div class="drawer-content">
              <a-form-model title="1d2" layout="vertical" ref="ruleForm" :wrapper-col="{ span: 18 }">
                  <a-row>
                      <a-col :span="24">
                          <a-form-model-item label="完成情况">
                              <a-textarea v-model="form.content"></a-textarea>
                          </a-form-model-item>
                      </a-col>
                      <a-col :span="12">
                        工单照片
                        <!-- head -->
                        <a-upload
                            list-type="picture-card"
                            :action="`${$upload}`"
                            :headers="uploadHeaders"
                            accept='.jpg,.JPG,.png,.PNG,.jpeg,.JPEG'
                            @preview="handlePreview"
                            @change="picChange"
                            :file-list="headList"
                            :beforeUpload="beforeUpload"
                        >
                            <div v-if="headList.length < 1">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                                上传照片
                            </div>
                            </div>
                        </a-upload>
                      </a-col>
                  </a-row>
                  <div>工单费用<a-button style="float: right" type="danger" ghost @click="delAll">全部删除</a-button></div>
                  <a-divider></a-divider>
                  <a-table :columns="columns" :data-source="form.workOrderFinishCostDTOList">
                      <template slot="costType" slot-scope="text,record,index">
                        <a-select v-model="form.workOrderFinishCostDTOList[index].costType">
                            <a-select-option :value="1">
                                人工费
                            </a-select-option>
                            <a-select-option :value="2">
                                耗材费
                            </a-select-option>
                        </a-select>
                      </template>
                      <template slot="name" slot-scope="text,record,index">
                          <a-input v-model="form.workOrderFinishCostDTOList[index].name"></a-input>
                      </template>
                      <template slot="num" slot-scope="text,record,index">
                          <a-input-number @change="totalPrice" v-model="form.workOrderFinishCostDTOList[index].num"></a-input-number>
                      </template>
                      <template slot="price" slot-scope="text,record,index">
                          <a-input-number :step="0.01" @change="totalPrice" v-model="form.workOrderFinishCostDTOList[index].price"></a-input-number>
                      </template>
                      <template slot="option" slot-scope="text,record,index">
                          <a @click="delSingle(index)" style="color: red">删除</a>
                      </template>
                      <template slot="footer">
                          <span>总费用：{{total}}</span>
                      </template>
                  </a-table>
                  <a-button type="primary" ghost @click="addFee">添加费用</a-button>
              </a-form-model>
          </div>
          <div class="drawer-footer">
            <a-button :style="{ marginRight: '8px' }" @click="onClose">
                关闭
            </a-button>
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
          </div>
      </a-drawer>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
  </div>
</template>

<script>
import store from "@/store";
import {finish} from "@/api/basic/Workorder";
const uploadHeaders = {
	"manage-login-token": store.getters.getToken,
}
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}
export default {
    name: "finishDrawer",
    props: {
		visible: Boolean,
        finishId: Number
	},
    watch: {
		visible: {
			handler(newValue) {
				this.isVisibleDrawe = newValue;
			},
			immediate: true,
		}
	},
    data() {
        return {
            form: {
                workOrderId: undefined,
                content: '',
                imgUrls: [],
                workOrderFinishCostDTOList: []
            },
            //图片上传
            uploadHeaders: uploadHeaders,
            headList: [],
            isVisibleDrawe: false,
            previewVisible:false,
            previewImage:'',
            //价格表
            columns: [
                {
                    title: "收费类型",
                    dataIndex: "costType",
                    key: "costType",
                    scopedSlots: { customRender: "costType" },
                },
                {
                    title: "收费名称",
                    dataIndex: "name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                },
                {
                    title: "数量",
                    dataIndex: "num",
                    key: "num",
                    scopedSlots: { customRender: "num" },
                },
                {
                    title: "单价(元)",
                    dataIndex: "price",
                    key: "price",
                    scopedSlots: { customRender: "price" },
                },
                {
                    title: "操作",
                    dataIndex: "option",
                    key: "option",
                    scopedSlots: { customRender: "option" },
                },
            ],
            tableData: [],
            total: 0,
        }
    },
    methods: {
        onSubmit() {
            //照片
            let pic = []
			for(let item of this.headList){
				// 新增的照片
				if(item.response){
					pic.push(item.response.data)
				}else{
				// 已添加的照片
					pic.push(item.url.split('/')[item.url.split('/').length-1])
				}
			};
            this.form.workOrderId = this.finishId;
            this.form.imgUrls = pic;
            let obj = {...this.form};
            finish(obj).then(res => {
                if(res.code === 200){
					this.$message.success(res.msg)
					this.success()
					this.addClose()
				} else {
					this.$message.error(res.msg)
				}
            })
        },
        onClose() {
			this.$emit("onClose");
		},
        addClose(){
            this.isVisibleDrawe = false;
            this.form.workOrderFinishCostDTOList = [];
            this.form.content = '';
			this.headList =  [];
		},
        addFee() {
            let obj = {
                costType: 1,
                name: '',
                num: 0,
                price: 0,
            }
            this.form.workOrderFinishCostDTOList.push(obj)
        },
        //删除全部和删除单个
        delSingle(index) {
            this.form.workOrderFinishCostDTOList.splice(index, 1);
        },
        delAll() {
            this.form.workOrderFinishCostDTOList = []
        },
        //上传限制
        beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		}, //图片上传
        async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
        picChange(info){
			this.headList = info.fileList
		},
        handleCancel() {
			this.previewVisible = false;
		},
        totalPrice() {
            let total = 0;
            this.form.workOrderFinishCostDTOList.forEach(ele => {
                let val = ele.num * ele.price;
                total = total + val
            })
            this.total = total
        },
        success(){
			this.$emit('success')
		},
    }
}
</script>

<style>

</style>