import {houseList} from "@/api/basic/estate";
import {departmentList} from "@/api/basic/department"
import {getAllWorkOrderType} from "@/api/basic/Workorder"
import {findEstateCascade} from "@/api/basic/estate"

let applicantIds = []
let estateIds = []
let workOrderTypeIds = []

departmentList().then(res => {
	let data = res.data;
	data.forEach(ele => {
		let childList = []
		ele.childList.forEach(el => {
			let chList = [];
			el.childList.forEach(e => {
				let obj = {
					value: e.id,
					label: e.name
				};
				chList.push(obj)
			})
			let obj = {
				value: el.id,
				label: el.name,
				children: chList,
			};
			childList.push(obj)
		})
		let obj = {
			value: ele.id,
			label: ele.name,
			children: childList
		};
		applicantIds.push(obj)
	});
})

// houseList({pageNum: 1,size: 100000}).then(res => {
// 	let data = res.data.rows;
// 	data.forEach(ele => {
// 		let obj = {
// 			id: ele.id,
// 			name: ele.manageUnitName+'/'+ele.floorLocation+'楼/'+ele.name
// 		};
// 		estateIds.push(obj);
// 	})
// })

findEstateCascade().then(res => {
	let data = res.data;
	data.forEach(ele => {
		estateIds.push(ele);
	})
})

getAllWorkOrderType().then(res => {
	let data = res.data;
	data.forEach(ele => {
		workOrderTypeIds.push(ele)
	})
})

export const form = {
	identity: undefined,
	applicantId: undefined,
	estateId: null,
	positionId: null,
	workOrderTypeId: undefined,
	reserveAddress: '',
	reserveDate: '',
	imgUrls: [],
	content: ''
}
export const rules = {
	identity:[{required:true,message:'请选择身份',trigger:'change'}],
	applicantId:[{required:true,message:'请选择申请人',trigger:'change'}],
	// estateId:[{required:true,message:'请选择房屋',trigger:'change'}],
	// positionId:[{required:true,message:'请选择岗位',trigger:'change'}],
	workOrderTypeId:[{required:true,message:'请选择工单类型',trigger:'change'}],
	reserveAddress:[{required:true,message:'请输入地址',trigger:'blur'}],
	reserveDate:[{required:true,message:'请选择时间',trigger:'change'}],
	content:[{required:true,message:'请输入工单内容',trigger:'blur'}],
}
export const options = {
	identity:[
		{ id:1, name:'住户' },
		{ id:2, name:'物业' },
	],
	applicantId: applicantIds, //岗位
	estateId: estateIds, //房屋
	workOrderTypeId: workOrderTypeIds, //工单类型
}
// 请求头
import store from "@/store";
export const uploadHeaders = {
	"manage-login-token": store.getters.getToken,
}