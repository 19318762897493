import httpService from "@/request"

// 工单
// list
export function name(params) {
	return httpService({
		url: ` `,
		method: 'get/post',
		data: params,
	})
}

// 所有的工单类型
export function getAllWorkOrderType(params) {
	return httpService({
		url: `/user/workOrderType/list`,
		method: 'get',
		params: params,
	})
}

// 添加工单类型
export function AddWorkOrderType(params) {
	return httpService({
		url: `/user/workOrderType/insert`,
		method: 'post',
		data: params,
	})
}
// 修改工单类型
export function EditWorkOrderType(params) {
	return httpService({
		url: `/user/workOrderType/update`,
		method: 'POST',
		data: params,
	})
}
// 删除工单类型
export function DelWorkOrderType(params) {
	return httpService({
		url: `/user/workOrderType/delete`,
		method: 'POST',
		data: params,
	})
}

// 所有的工单信息
export function getAllWorkOrder(params) {
	return httpService({
		url: `/user/workOrder/list`,
		method: 'get',
		params: params,
	})
}

// 查询后台工单详情
export function getDetail(params) {
	return httpService({
		url: `/user/workOrder/detail`,
		method: 'get',
		params: params,
	})
}

// 添加工单信息
export function addWorkOrder(params) {
	return httpService({
		url: `/user/workOrder/insert`,
		method: 'post',
		data: params,
	})
}

// 取消后台工单信息
export function cancelWorkOrder(params) {
	return httpService({
		url: `/user/workOrder/cancel`,
		method: 'post',
		data: params,
	})
}

// 加入工单池
export function joinTicketPool(params) {
	return httpService({
		url: `/user/workOrder/joinTicketPool`,
		method: 'get',
		params: params,
	})
}

// 转移至待分配
export function moveToAssignment(params) {
	return httpService({
		url: `/user/workOrder/moveToAssignment`,
		method: 'get',
		params: params,
	})
}

// 指派人员
export function designate(params) {
	return httpService({
		url: `/user/workOrder/designate`,
		method: 'post',
		data: params,
	})
}

// 转派人员
export function updateDesignate(params) {
	return httpService({
		url: `/user/workOrder/updateDesignate`,
		method: 'post',
		data: params,
	})
}

// 工单处理
export function process(params) {
	return httpService({
		url: `/user/workOrder/process`,
		method: 'get',
		params: params,
	})
}

// 完成工单
export function finish(params) {
	return httpService({
		url: `/user/workOrder/finish`,
		method: 'post',
		data: params,
	})
}

// 确认工单
export function confirm(params) {
	return httpService({
		url: `/user/workOrder/confirm`,
		method: 'get',
		params: params,
	})
}

// 工单结算账单
export function workOrderCostList(params) {
	return httpService({
		url: `/user/workOrder/workOrderCostList`,
		method: 'get',
		params: params,
	})
}

// 结算工单
export function settlement(params) {
	return httpService({
		url: `/user/workOrder/settlement`,
		method: 'post',
		data: params,
	})
}

// 汇报
export function report(params) {
	return httpService({
		url: `/user/workOrder/report`,
		method: 'post',
		data: params,
	})
}

// 查询工单记录
export function orderSchedule(params) {
	return httpService({
		url: `/user/workOrder/orderSchedule`,
		method: 'get',
		params: params,
	})
}