import httpService from "@/request"

// 组织
// 所有的组织架构
export function departmentList(params) {
	return httpService({
		url: `/user/department/list`,
		method: 'get',
		data: params,
	})
}
// 添加组织
export function departmentInsert(params) {
	return httpService({
		url: `/user/department/insert`,
		method: 'post',
		data: params,
	})
}
// 组织删除
export function departmentDel(params) {
	return httpService({
		url: `/user/department/delete`,
		method: 'post',
		data: params,
	})
}
// 组织修改
export function departmentUpdate(params) {
	return httpService({
		url: `/user/department/update`,
		method: 'post',
		data: params,
	})
}
// 组织员工
export function findToUser(params) {
	return httpService({
		url: `/user/department/findToUser`,
		method: 'GET',
		params: params,
	})
}
// 新增员工
export function insertManageUser(params) {
	return httpService({
		url: `/user/manageUser/insertManageUser`,
		method: 'post',
		data: params,
	})
}
// 上传身份证照片
export function uploadManageUserIdCard(params) {
	return httpService({
		url: `/user/upload/uploadManageUserIdCard`,
		method: 'post',
		data: params,
	})
}
//  员工info
export function manageUserInfo(params) {
	return httpService({
		url: `/user/manageUser/findById`,
		method: 'GET',
		params: params,
	})
}
//  员工edit
export function EditManageUser(params) {
	return httpService({
		url: `/user/manageUser/update`,
		method: 'post',
		data: params,
	})
}
//  员工开启关闭
export function userEnable(params) {
	return httpService({
		url: `/user/manageUser/isEnable`,
		method: 'GET',
		params: params,
	})
}
// 重置密码
export function rePassword(params) {
	return httpService({
		url: `/user/manageUser/rePassword`,
		method: 'post',
		data: params,
	})
}
